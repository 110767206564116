exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-us-template-tsx": () => import("./../../../src/templates/AboutUsTemplate.tsx" /* webpackChunkName: "component---src-templates-about-us-template-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-news-article-template-tsx": () => import("./../../../src/templates/news/articleTemplate.tsx" /* webpackChunkName: "component---src-templates-news-article-template-tsx" */),
  "component---src-templates-news-news-template-tsx": () => import("./../../../src/templates/news/newsTemplate.tsx" /* webpackChunkName: "component---src-templates-news-news-template-tsx" */),
  "component---src-templates-news-project-template-tsx": () => import("./../../../src/templates/news/projectTemplate.tsx" /* webpackChunkName: "component---src-templates-news-project-template-tsx" */),
  "component---src-templates-news-projects-list-template-tsx": () => import("./../../../src/templates/news/projectsListTemplate.tsx" /* webpackChunkName: "component---src-templates-news-projects-list-template-tsx" */),
  "component---src-templates-servicio-tsx": () => import("./../../../src/templates/servicio.tsx" /* webpackChunkName: "component---src-templates-servicio-tsx" */)
}

